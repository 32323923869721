import preparePutPaymentData from 'shared/redux/features/payment/api/utils/preparePutPaymentData'

import { api } from '@redux/api/api'
import type { PaymentMethodsListType } from '@redux/features/checkout/types/payment'

import type { PaymentResponseType } from '@Types/payment/paymentResponse/putPaymentResponse'
import authStorage from '@storageServices/storageEntities/auth'
import paymentStorage from '@storageServices/storageEntities/payment'
import type { PaymentFormType } from '@utils/forms/payment/types'
import transformPaymentMethodsResponse from '@utils/payment/transformPaymentMethodsResponse'

import paymentUrls from '../constants/urls'

const paymentApi = api.injectEndpoints({
  endpoints: build => ({
    createEcommpayAppleSession: build.mutation<string, EcommpayAppleSessionPayloadType>({
      query: body => ({
        body,
        method: 'POST',
        url: paymentUrls.ecommpayAppleSession,
      }),
    }),
    getPayment: build.query<PaymentResponseType, string>({
      keepUnusedDataFor: 0,
      query: url => ({ keepUnusedDataFor: 0, url }),
    }),
    getPaymentMethods: build.query<PaymentMethodsListType, string>({
      query: url => ({ url }),
      transformResponse: transformPaymentMethodsResponse,
    }),
    patchPayment: build.mutation<
      PaymentResponseType,
      { data?: Record<string, string>; lastActionKey: number | string; url: string }
    >({
      query: ({ data, lastActionKey, url }) => {
        const lastAction = paymentStorage.getValueByKey('lastActions')?.[lastActionKey]
        return { body: { data, lastAction }, method: 'PATCH', url }
      },
    }),
    putPayment: build.mutation<PaymentResponseType, { formValues: PaymentFormType; url: string }>({
      query: ({ formValues, url }) => ({ body: preparePutPaymentData(formValues), method: 'PUT', url }),
      transformResponse: (response: PaymentResponseType, _, { url }) => {
        if (response.authToken) {
          const authData = {
            userId: response.uid,
            'X-Auth-Token': response.authToken,
          }
          authStorage.setValue(url.includes('internal/train/booking') ? { temp: authData } : authData) //for backend session invalidation https://firebird.atlassian.net/browse/ITOP-14682?focusedCommentId=96485
        }

        return response
      },
    }),
  }),
})

export const {
  endpoints: paymentEndpoints,
  useGetPaymentMethodsQuery,
  useGetPaymentQuery,
  useLazyGetPaymentQuery,
  usePatchPaymentMutation,
  usePutPaymentMutation,
} = paymentApi
